import React from "react"
import styled from "styled-components"

// The 'emphasis' prop can be added to the navItem component to add specific styles.

const NavItemStyled = styled.li`
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0 10px;
    background-color: transparent;
    border: 4px solid transparent;
    white-space: nowrap;

    &:hover {
        border-bottom: 4px solid ${props => props.theme.colors.primaryAccent};
        transition: all 0.3s ease-in-out;
        ${props =>
          props.emphasis &&
          `
        background-color: ${props.theme.colors.primaryAccent};
        `}
    }

    ${props =>
      props.emphasis &&
      `
        background-color: ${props.theme.colors.secondaryAccent};
        border-radius: 100px;
    `}

    @media ${props => props.theme.media.laptop} {
    }
    @media ${props => props.theme.media.tablet} {
        display: none;
    }
`

const navItem = props => {
  return <NavItemStyled {...props}>{props.children}</NavItemStyled>
}

export default navItem
