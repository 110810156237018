import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

import NavItem from "./NavItem/NavItem"

const NavStyled = styled.ul`
  display: flex;
  align-items: center;
  width: auto;
  justify-content: flex-end;
`

const ResNavStyled = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
`

const Hamburger = styled(NavItem)`
  cursor: pointer;
  display: none;
  &:hover {
    border-color: transparent;
    color: ${props => props.theme.colors.primaryAccent};
  }
  @media ${props => props.theme.media.tablet} {
    display: inline-block;
  }
`

const ResNavItemStyled = styled(NavItem)`
  padding: 20px 0;
  background: transparent;
  color: ${props => props.theme.colors.lightFont};
  @media ${props => props.theme.media.tablet} {
    display: block;
  }
`

const nav = props => {
  let navBar = (
    <NavStyled>
      <Link to="/#services">
        <NavItem>Find me</NavItem>
      </Link>
      <Link to="/#about">
        <NavItem>About</NavItem>
      </Link>
      <Link to="/#work">
        <NavItem>My Work</NavItem>
      </Link>
      <Link to="/#contact">
        <NavItem emphasis>Contact</NavItem>
      </Link>
      <Hamburger onClick={props.clicked}>
        <FontAwesomeIcon
          icon={!props.hamburgerState ? faBars : faTimes}
          size="lg"
        />
      </Hamburger>
    </NavStyled>
  )
  if (props.responsive) {
    navBar = (
      <ResNavStyled>
        <Link onClick={props.navItemClicked} to="/#services">
          <ResNavItemStyled>Services</ResNavItemStyled>
        </Link>
        <Link onClick={props.navItemClicked} to="/#about">
          <ResNavItemStyled>About</ResNavItemStyled>
        </Link>
        <Link onClick={props.navItemClicked} to="/#work">
          <ResNavItemStyled>My Work</ResNavItemStyled>
        </Link>
        <Link onClick={props.navItemClicked} to="/#process">
          <ResNavItemStyled>The Process</ResNavItemStyled>
        </Link>
        <Link onClick={props.navItemClicked} to="/#contact">
          <ResNavItemStyled emphasis>Contact</ResNavItemStyled>
        </Link>
      </ResNavStyled>
    )
  }

  return navBar
}

export default nav
