import React from "react"
import styled from "styled-components"
import logoImage from "../../../../images/idlogo.png"

const LogoStyled = styled.img`
  padding: 12px;
  max-height: 95px;

  @media ${props => props.theme.media.mobileL} {
    font-size: 2em;
  }
`

const logo = props => {
  return <LogoStyled src={logoImage} alt="logo" />
}

export default logo
