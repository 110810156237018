import React from "react"
import styled from "styled-components"

const HeadingStyled = styled.h3`
    font-size: 40px;
    color: ${props =>
      !props.textColor ? props.theme.colors.darkFont : props.textColor};
    text-align: ${props => (!props.align ? "center" : props.align)};
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 16px;

    @media ${props => props.theme.media.mobileL} {
        font-size: 30px;
        margin-bottom: 8px;
        ${props =>
          props.minor &&
          `
          font-size: 20px;
          `}
    }

    ${props =>
      props.minor &&
      `
        font-size: 24px;
        color: ${
          !props.textColor ? props.theme.colors.darkFont : props.textColor
        };
        text-align: ${!props.align ? "inherit" : props.align};
        margin: 0.8rem 0;
        padding: 0;
    `}

    @media ${props => props.theme.media.tablet} {
        text-align: ${props => (!props.align ? "center" : props.align)};
    }

    @media ${props => props.theme.media.mobileL} {
        text-align: center;
    }


`

const heading = props => {
  return <HeadingStyled {...props}>{props.children}</HeadingStyled>
}

export default heading
