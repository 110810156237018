// Here a theme is defined. This theme is passed to the entire site (via index.js) so the individual parts of the theme object
// can be accessed in each styled component.

export const theme = {
  colors: {
    primaryAccent: "#A93F55", // pink
    secondaryAccent: "#4595AE", // blue
    lightAccent: "#8D99AE", // grey
    lightFont: "#EDF2F4", // whitey grey
    darkFont: "#0A0F0D", // off black
    opacityLight: "rgba(255, 255, 255, 0.5 )",
    opacitySecondaryAccent: "rgba(237, 242, 244, 0.5)", // white semi-transparent
    opacityFilterDark: "rgba(10, 15, 13, 0.5)",
  },
  fonts: {
    primary: '"Segoe UI", sans-serif',
    accent: '"Patua One", serif',
  },
  widths: {
    content: "80%",
    contentMargin: "10%",
  },
  media: {
    laptop: "(max-width: 1500px)",
    tablet: "(max-width: 1000px)",
    mobileL: "(max-width: 500px)",
    mobileS: "(max-width: 320px)",
  },
}
