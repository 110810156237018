import React from "react"
import styled from "styled-components"
import Nav from "../Nav/Nav"

const HamburgerMenuStyled = styled.div`
  width: 100%;
  height: 0;
  position: fixed;
  top: 95px;
  background: ${props => props.theme.colors.primaryAccent};
  opacity: 0;
  z-index: 900;
  transition: all 0.3s ease-in;
  overflow: hidden;

  ${props =>
    props.visible &&
    `
        transition: all 0.3s ease-in;
        height: 380px;
        opacity: 1;
    `}
`

const hamburgerMenu = props => {
  return (
    <HamburgerMenuStyled {...props}>
      <Nav responsive navItemClicked={props.navItemClicked} />
    </HamburgerMenuStyled>
  )
}

export default hamburgerMenu
