import React from "react"
import styled from "styled-components"

const PageSectionStyled = styled.div`
  width: 100%;
  background: ${props => props.background};
  background-size: ${props => props.backgroundSize};
  display: flex;
  flex-direction: column;
  align-items: ${props => (!props.align ? "center" : props.align)};
  padding: 72px 0;
  position: relative;
`

const pageSection = props => {
  return (
    <PageSectionStyled {...props} id={props.id}>
      {props.children}
    </PageSectionStyled>
  )
}

export default pageSection
